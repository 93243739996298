import React from "react"
import Layout from "../components/layout/layout"

function ToSPage () {

    return (
        <Layout>
            <section className="grid grid-cols-12 my-32">
                <div className="col-start-3 col-span-8">
                    <h1>Términos y Condiciones de Uso</h1>
                    www.alexseguro.com (nombrado aquí el “Sitio”). Al acceder y utilizar este Sitio, usted acuerda que ha leído y comprendido los Términos y Condiciones de Uso que está publicado en el Sitio en el momento, y acuerda revisar los Términos y Condiciones de Uso publicados cada vez que utilice este Sito.<br/><br/>
                    La Política de Privacidad esta incorporada en este Sitio y es parte de los Términos y Condiciones de Uso.<br/><br/>
                    El contenido y material usado en esta página, incluyendo imágenes y textos, es de índole general y su único propósito es informativo.<br/><br/>

                    <h2 className="mt-4">USO DEL CONTENIDO</h2>
                    El contenido de este Sitio es gestionado de acuerdo a la Ley de Propiedad Intelectual, Código de EE.UU. Titulo 17. El contenido incluye marcas, marcas de servicio, material de propiedad intelectual, marcas registradas y no registradas de REDBRIDGE, sus afiliadas y sus licenciantes. REDBRIDGE a su discreción absoluta, todos los derechos y títulos del contenido, marcas, marcas de servicios registradas y material de propiedad intelectual, marcas registradas y no registradas incluidas en este Sitio, permanecerán a REDBRIDGE, sus afiliadas o sus licenciantes. <br/><br/>
                    REDBRIDGE le autoriza a usted visitar este Sitio, por su cuenta y riesgo, y hacer una copia del contenido para su uso personal, siempre que no sea publicado, abusado, citado, uso inapropiado o alterado y que los avisos de propiedad intelectual o marca de propiedad que aparecen en el contenido no sean bloqueado o removidos. Todos los derechos no expresamente otorgados aquí, son derechos reservados de REDBRIDGE, sus afiliadas y sus licenciantes, y en caso de infracción de cualquiera de las condiciones expresadas arriba, la autorización de visitar y ver el contenido de este Sitio será automáticamente terminado; usted queda obligado a inmediatamente destruir cualquier y todas las copias electrónica o impresa del contenido en su posesión. Ninguna porción de este Sitio o su contenido podrá ser reproducido, duplicado, copiado, vendido, negociado, revendido o explotado para negocios u otras ganancias.<br/><br/>
                    Nada podrá circunvalar o desactivar cualquiera de las medidas de seguridad adoptadas por el Sitio (incluyendo, sin estar limitado a, la difusión de cualquier código de usuario o acceso emitidos a otros). Usted no podrá acceder el código fuente (aparte del código legible HTML disponible a través de la operación ordinaria en navegadores comerciales) que es utilizado para implementar y operar el Sitio por cualquier medio, incluyendo, pero sin estar limitado a dañar dicho código fuente por ingeniería inversa, de compilación o desmontaje del código ejecutante accesible a través del Sitio.  Usted no podrá enlazar a este Sitio.<br/><br/>

                    <h2 className="mt-4">CONTROL DE ACCESO</h2>

                    Su única responsabilidad es: (1) control de diseminación y uso de los códigos de activación y claves; (2) autorizar, monitorear y controlar el acceso a su cuenta y su contraseña individual, y el uso de estas; (3) informar oportunamente al técnico de control de seguridad de REDBRIDGE sobre la necesidad de desactivar cualquier contraseña. Usted otorga a REDBRIDGE y todas las demás personas y entidades que participan en la operación de este Sitio, el derecho a transmitir, monitorear, almacenar y usar todos y cualquiera de sus datos en conexión con la operación de este Sitio.<br/><br/>

                    <h2 className="mt-4">TERMINACIÓN</h2>

                    REDBRIDGE se reserva el derecho, a su entera discreción, de revocar, reducir, limitar, restringir o de cualquier otra manera modificar su acceso a este Sitio, y de terminar dicho acceso, con o sin causa, por cualquier o sin razón alguna. Estas acciones son adicionales y no reemplaza o limita cualquier otro derecho o recurso que REDBRIDGE pudiera tener a su disposición a tenor bajo la ley o equidad. Además, REDBRIDGE no será responsable ante usted o cualquier tercera parte por cualquier terminación, restricción, cambio, enmienda o interrupción de su acceso.<br/><br/>

                    <h2 className="mt-4">ELECCIÓN DE DERECHO Y JURISDICCIÓN </h2>

                    Usted expresamente acuerda que la jurisdicción exclusiva para cualquier controversia con REDBRIDGE, o que de cualquier modo esté relacionado con el uso de este Sitio, reside en los tribunales de Barbados y usted también consiente al ejercicio de la jurisdicción personal en los tribunales de Barbados en conexión con cualquiera de las controversias antes mencionadas, incluyendo cualquier reclamación que implique a REDBRIDGE, sus afiliadas, subsidiarias, empleados, contratistas, oficiales y/o directores.<br/><br/>

                    Los Términos y Condiciones de Uso de este Sitio están regidos por las leyes sustantivas internas de Barbados, indistintamente de sus principios en conflicto de ley. Si alguna disposición de estos Términos y Condiciones de Uso fuese considerada nula y sin efecto por el tribunal de jurisdicción competente, la invalidación de dicha disposición no afectará la validez de las demás disposiciones de estos Términos y Condiciones de Uso, las cuales permanecerán en pleno vigor.<br/><br/>
                    
                    <h2 className="mt-4">RESPONSABILIDAD DE REDBRIDGE, SUS AFILIADOS Y SUS LICENCIANTES</h2>

                    EL ACCESO Y USO DEL CONTENIDO DE ESTE SITIO ES POR RIESGO Y CUENTA PROPIA<br/><br/>

                    Este sitio y su contenido se proporcionan “COMO ESTÁ”.  No deberá asumirse que el contenido es completo o actualizado sobre cualquier tema en particular. REDBRIDGE no asume responsabilidad alguna que surja de, o relacionada con, la demora, fallo, omisión, acción, inacción, interrupción o corrupción de cualesquiera datos o demás información que se transmitan en conexión con el uso de este Sitio.<br/><br/>

                    REDBRIDGE, sus afiliadas, licenciantes y suplidores no asumen responsabilidad alguna por cualquier daño directo, indirecto, incidental, especial, consecuente u otros daños, sean estos punitivos o de otra índole, previstos o imprevistos, de cualquier índole que sea, que surjan de este Sitio o en conexión con este, ya sea por estar basado en garantía, contrato, delito,  negligencia, acto, error u omisión, responsabilidad del producto, u otras teorías legales o equitativas, aún sí REDBRIDGE, sus afiliados, sus licenciantes y suplidores hayan recibido aviso directo o indirecto sobre cualquier defecto, omisión o modificación requerida del Sitio. REDBRIDGE no es responsable por concepto de cualquier decisión tomada o acción tomada en relación con el contenido o el Sitio.<br/><br/>

                    REDBRIDGE, sus afiliadas, licenciantes y suplidores no hacen declaración ni garantía alguna sobre (1) la exactitud, confiabilidad, integridad, puntualidad o cualidad del contenido, del programa informático (software), de los enlaces o de las comunicaciones ofrecidos en el Sitio o mediante el uso del mismo, y (2) cumplimiento de las normas gubernamentales que requiere la revelación de información, o la aprobación o cumplimiento de cualquier programa informático (software) con respecto al contenido proporcionado en el Sitio.<br/><br/>

                    REDBRIDGE, SUS AFILIADAS, SUS LICENCIANTES Y SUPLIDORES, EXPRESAMENTE RECHAZA CUALQUIER GARANTÍA POR CONCEPTO DEL CONTENIDO DE ESTE SITIO, YA SEA EXPRESA O IMPLÍCITA, ESTATUTORIA U OTRA ÍNDOLE, INCLUYENDO SIN ESTAR LIMITADO A GARANTÍAS IMPLÍCITAS DE COMERCIABILIDAD E IDONIEDAD PARA CUALQUIER PROPÓSITO EN PARTICULAR, INCUMPLIMIENTO DE DERECHOS, TÍTULO Y APTITUD DE TERCEROS.<br/><br/>
                    
                    <h2 className="mt-4">INDENIZACIÓN</h2>

                    Usted acuerda defender, indemnizar y eximir a REDBRIDGE, sus afiliadas, sus oficiales, directores, empleados, agentes, licenciantes y suplidores de cualquier y todas reclamaciones, acciones, o demandas, obligaciones y transacciones (incluyendo, sin estar limitado a, honorarios o costos de abogados y contables), resultantes de, o alegados como resultado de su infracción de los Términos y Condiciones de Uso.<br/><br/>

                    <h2 className="mt-4">ENLACES EXTERNOS</h2>

                    Este Sitio podrá incluir enlaces a sitios en Internet de terceras partes. Los sitios enlazados no están bajo el control de REDBRIDGE; por tanto, REDBRIDGE no es responsable por el contenido de ninguno de tales sitios enlazados. REDBRIDGE proporciona estos enlaces para facilidad únicamente, y el hecho que un enlace este incluido no significa que REDBRIDGE endosa o patrocina el sitio enlazado, ni que está afiliado con el mismo. Es su responsabilidad investigar dichos enlaces y la información contenida en ellos según usted lo estime necesario o apropiado, antes de proceder a realizar cualquier transacción en alguno de esas terceras partes.  El uso o acceso a sitios en Internet de terceras partes es bajo su propio riesgo y estará sujeto a los Términos y Condiciones de Uso de dichos sitios. REDBRIDGE declina toda y cualquier responsabilidad por cualquier información expresada en sitios enlazados.<br/><br/>

                    <h2 className="mt-4">PREGUNTAS</h2>

                    Cualquier pregunta relacionada a los Términos y Condiciones de Uso, agradecemos contactar a  REDBRIGE a: info@redbridge.cc.
                    Si usted se percata de alguna violación sobre la seguridad del Sitio, o infracción de estos Términos y Condiciones de Uso, o de la Política de Privacidad, agradecemos nos contacte de inmediato.<br/><br/>

                    <h2 className="mt-4">FECHA DE VIGENCIA</h2>

                    Términos y Condiciones de Uso entra en vigor Junio 1ro, 2015.<br/><br/>

                    Fecha de Revisión Mayo 1ro, 2020.<br/><br/>
                </div>

            </section>
        </Layout>
    )
}

export default ToSPage